import React, { useState, useEffect } from 'react';
import { Link } from 'gatsby';
import { injectIntl } from 'gatsby-plugin-intl';
import classNames from 'classnames';

import { useSiteMetadata } from '../../hooks';

import leiPassLogo from '../../images/leipass-logo-white.svg';
import Navigation from '../Navigation';

import styles from './header.module.scss';

const Header = ({ isInverted, ...props }) => {
  const { title } = useSiteMetadata();

  const [isOpen, toggleOpen] = useState(false);
  const [isScrolled, toggleScrolled] = useState(false);

  const toggle = () => {
    toggleOpen(!isOpen);
  };

  function handleScroll() {
    const scrollPos = window.pageYOffset || document.documentElement.scrollTop;

    toggleScrolled(scrollPos > 40);
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const headerStyles = classNames(styles.header, {
    [styles.isScrolled]: isScrolled,
    [styles.isInverted]: isInverted,
    [styles.isOpen]: isOpen,
  });

  const locale = props.intl.locale.split('-');

  return (
    <header className={headerStyles}>
      <div className={styles.container}>
        <Link className={styles.logo} to="/">
          <img className={styles.logoImage} src={leiPassLogo} alt={title} />
          <span className={styles.logoLocale}>{locale[locale.length - 1]}</span>
          <span className="visually-hidden">{title}</span>
        </Link>
        <button
          type="button"
          className={classNames(styles.toggle, { [styles.isOpen]: !!isOpen })}
          onClick={toggle}
        >
          <span className="visually-hidden">Toggle</span>
        </button>
        <Navigation
          className={classNames(styles.navigation, { [styles.isOpen]: !!isOpen })}
          id="GXmJ4OgJljZAkbdU8IS0v"
        />
      </div>
    </header>
  );
};

export default injectIntl(Header);
