import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { graphql, useStaticQuery } from 'gatsby';
import { Link, injectIntl } from 'gatsby-plugin-intl';

import styles from './navigation.module.scss';

const Navigation = ({
  id, heading, intl, ...props
}) => {
  if (!id) return null;

  const componentClasses = classNames(styles.navigation, props.className);

  const { allContentfulNavigation: navigation } = useStaticQuery(graphql`
    query Navigation {
      allContentfulNavigation {
        edges {
          node {
            node_locale
            contentful_id
            items {
              name
              slug
              contentful_id
              sys {
                contentType {
                  sys {
                    id
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  const { node: nav } = navigation.edges.filter(({ node }) => node && (
    node.contentful_id === id && node.node_locale === intl.locale
  )).shift();

  return (
    <nav className={componentClasses}>
      { heading && <h4 className={styles.heading}>{heading}</h4> }
      <ul className={styles.list}>
        { nav.items.map(({
          contentful_id: contentfulId, sys, slug, name,
        }) => (
          <li key={contentfulId} className={styles.listItem}>
            <Link
              className={styles.link}
              to={`/${sys.contentType.sys.id}/${slug}`}
            >
              {name}
            </Link>
          </li>
        ))}
      </ul>
    </nav>
  );
};

Navigation.propTypes = {
  id: PropTypes.string,
  heading: PropTypes.string,
};

export default injectIntl(Navigation);
