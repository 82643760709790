/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';

import GlobalStyles from '../GlobalStyles';
import Header from '../Header';
import Footer from '../Footer';

import styles from './layout.module.scss';

const Layout = ({ children, ...props }) => (
  <div className={classNames(styles.site, { [styles.isInverted]: props.isInverted })}>
    <GlobalStyles />
    <Header isInverted={props.isInverted} />
    <main className={styles.main}>
      {children}
    </main>
    <Footer />
  </div>
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  isInverted: PropTypes.bool,
};

export default Layout;
