import { createGlobalStyle } from 'styled-components';

import WorkSansMediumWoff from '../../fonts/worksans-medium-webfont.woff';
import WorkSansMediumWoff2 from '../../fonts/worksans-medium-webfont.woff2';
import WorkSansSemiboldWoff from '../../fonts/worksans-semibold-webfont.woff';
import WorkSansSemiboldWoff2 from '../../fonts/worksans-semibold-webfont.woff2';

export default createGlobalStyle`
  @font-face {
    font-family: 'Work Sans';
    src: url(${WorkSansMediumWoff2}) format('woff2'), url(${WorkSansMediumWoff}) format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Work Sans';
    src: url(${WorkSansSemiboldWoff2}) format('woff2'), url(${WorkSansSemiboldWoff}) format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
  }
`;
